import {
  Autocomplete,
  Box,
  FormControl,
  Modal,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import "../../assets/styles/Calllog.css";
import { BeatLoader } from "react-spinners";
import ModalError from "../error/ModalError";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { DataGrid } from "@mui/x-data-grid";
import ContextState from "../contextApi/ContextState";
import { FaPhoneAlt } from "react-icons/fa";
import close from "../../assets/images/close.svg";
import { formattingString } from "../analytics/DataPull";
import { useSelector } from "react-redux";

const color = {
  primary: "#f7f7f9", // website background
  // secondary: "#8884d8",
  // secondary: "#826af9", // blue color in dashboard
  // secondary: "#0098e9", // blue color in dashboard
  secondary: "#666bff", // blue color in dashboard
  green: "#82ca9d", // green color in dashboard
  greenToShow: "#72e128", // green color in dashboard
  red: "#ff4d49d9", // red color in dashboard
  orange: "#fdb528", // orange color in dashboard
  navBorderBottom: "#4c4e6422",
  taxtHeadingLight: "#4c4e6499", // normal text heading light color
  taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
  tableRowHeading: "#4c4e64de", // (76,78,100,0.87)
  iconBackgroundBlue: "#666bff39",
  iconBackgroundWhite: "#ffffffe6",
  iconBackgroundgreen: "#82ca9d39",
  iconBackgroundred: "#ff4d4939",
  iconBackgroundorange: "#fff3dd",
  textWhiteColor: "#ffffff",
  textDescBackgroundRegionDiv: "#5b61e5",
  locationTextHeading: "#4c4e64fc", //location heeading color
  option_1: "#8884d8",
  option_1_bg: "#8884d830",
  option_2: "#82ca9d",
  option_2_bg: "#82ca9d30",
  option_3: "#fdb528d9",
  option_3_bg: "#fdb52830",
  option_4: "#26c6f9d9",
  option_4_bg: "#26c6f930",
  upper_limit: "#ff4d49d9",
  lower_limit: "#ff4d4966",
};

// converting gmt to gmt +5:30
function addTimeAndFormat(dateString) {
  // Parse the input date string
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date)) {
    return "Invalid date";
  }

  // Add 5 hours and 30 minutes
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  // Format the output
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedDate = date.toLocaleString("en-US", options);

  // Return the formatted date
  return formattedDate
    .replace(",", "")
    .replace(" AM", " AM")
    .replace(" PM", " PM");
}

// Modal for detailed call log-------->
function CallLogModal(props) {
  // const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const { fetchApi, openSnackbar } = useContext(ContextState);
  const {
    onClose,
    isOpen,
    selectedRowData,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
  } = props;

  const [linksData, setLinksData] = useState(null);
  const [modalLoading, setModalLoading] = useState(true);
  let ismount;
  useEffect(() => {
    ismount = true;
    if (isOpen && ismount) {
      fetchCallAudio();
    }

    return () => {
      ismount = false;
    };
  }, []);

  // fetching of the call recording api
  const fetchCallAudio = async () => {
    try {
      const payload = {
        uuid: selectedRowData.uuid,
      };

      const response = await fetchApi(
        "get_each_uuid_data",
        "POST",
        payload,
        ismount
      );

      if (response?.statusCode === 200) {
        setLinksData(response?.data);
      } else if (response?.statusCode === 202) {
        setLinksData(response);
        openSnackbar(response?.msg);
      } else if (response?.statusCode === 500) {
        setLinksData(null);
        openSnackbar(response.msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={onClose}
          style={{
            position: "fixed",
            overflowY: "scroll",
            height: "100%",
            margin: "0px 0px 0px 10px ",
          }}
        >
          <Box
            style={{
              position: "relative",
              right: "0",
              top: "20%",
              left: "0",
              margin: "auto",
              overflowY: "auto",
              borderRadius: "10px",
              backgroundColor: "#ffffff",
              maxWidth: "900px",
              maxHeight: "60vh",
              width: "85vw",
            }}
          >
            {modalLoading ? (
              <div
                className="showSpinner "
                style={{
                  position: "relative",
                  height: "38vh",
                  width: "100%",
                }}
              >
                <BeatLoader color="#2D97D4" />
              </div>
            ) : (
              <div>
                {" "}
                {/* modal header */}
                <Box
                  className="d-flex justify-content-between align-items-center px-3 py-2 position-relative"
                  style={{
                    borderBottom: "1px solid #f5f5f5",
                    // backgroundColor: (showError.status && color.primary) || "inherit",
                  }}
                >
                  <p
                    className="mb-0"
                    style={{
                      fontFamily: "Open-Sans-regular",
                      fontWeight: 500,
                      fontSize: isSmallSmallScreen
                        ? "1.0rem"
                        : isSmallScreen
                        ? "1.25rem"
                        : "1.5rem",
                      color: color.taxtHeading,
                    }}
                  >
                    {
                      // !loading
                      true && `Call Log Summary`
                    }
                  </p>

                  <div onClick={onClose}>
                    <img
                      src={close}
                      alt="close"
                      style={{ width: "20px", cursor: "pointer" }}
                    />
                  </div>
                </Box>
                {/* Modal Content start  */}
                <Box className="p-3">
                  <div
                    className="upper-card-basic-info position-relative mb-3 position-relative p-3 mx-1"
                    style={{ boxShadow: "none" }}
                  >
                    <div
                      className="moredetail-gridstyle "
                      style={{
                        gridTemplateColumns: isSmallSmallScreen
                          ? "1fr"
                          : isSmallScreen
                          ? "repeat(2, 1fr)"
                          : isMediumScreen
                          ? "repeat(3, 1fr)"
                          : "repeat(3, 1fr)",

                        gridRowGap: "25px",
                        // paddingTop: "25px",
                      }}
                    >
                      {[
                        "uuid",
                        // "ticket_id",
                        "caller_number",
                        "machines",
                        "duration",
                        "region",
                        "event_type",
                        "start_time",
                        "call_recording",
                        "event_message",
                        "notes",
                      ].map((showValues, index) => (
                        <p
                          key={`${showValues}-${index}`}
                          style={{
                            margin: "0px",
                            gridColumnStart:
                              showValues === "event_message" ||
                              showValues === "notes"
                                ? "1"
                                : "",
                            gridColumnEnd:
                              showValues === "event_message" ||
                              showValues === "notes"
                                ? "-1"
                                : "",
                            display:
                              showValues === "notes"
                                ? selectedRowData[showValues] == "" ||
                                  selectedRowData[showValues] == null
                                  ? "none "
                                  : ""
                                : null,
                          }}
                        >
                          <label
                            htmlFor={`outlined-read-only-input-${showValues}`}
                            className="basic_details_elements_label_data_table"
                            style={{
                              textTransform: "capitalize",
                              fontSize: isSmallScreen ? "0.785rem" : "0.85rem",
                            }}
                          >
                            {formattingString(showValues)}
                          </label>
                          <div
                            id={`outlined-read-only-input-${showValues}`}
                            className="basic_details_elements_data_content"
                            style={{
                              fontSize:
                                (isSmallScreen && "0.945rem") || "1.25rem",
                            }}
                          >
                            {showValues === "call_recording" ? (
                              linksData.statusCode !== 202 ? (
                                <div className="d-flex gap-2">
                                  <div>
                                    <Tooltip
                                      data-html="true"
                                      arrow={true}
                                      title="Click to listen call recording."
                                    >
                                      <a
                                        href={linksData?.recording_link}
                                        target="_blank"
                                      >
                                        <i className="bi bi-play-circle"></i>{" "}
                                      </a>
                                    </Tooltip>
                                  </div>
                                  <div>
                                    <Tooltip
                                      data-html="true"
                                      arrow={true}
                                      title="Click to download call recoding."
                                    >
                                      <a href={linksData?.download_link}>
                                        <i className="bi bi-arrow-down-circle"></i>
                                      </a>
                                    </Tooltip>
                                  </div>
                                </div>
                              ) : (
                                <div> No links found </div>
                              )
                            ) : Array.isArray(selectedRowData[showValues]) ? (
                              selectedRowData[showValues].length > 0 ? (
                                selectedRowData[showValues].join(",")
                              ) : (
                                ""
                              )
                            ) : showValues === "start_time" ? (
                              addTimeAndFormat(selectedRowData[showValues])
                            ) : (
                              selectedRowData[showValues]
                            )}
                          </div>
                        </p>
                      ))}
                    </div>
                  </div>
                </Box>
                {/* Modal Content ends */}
              </div>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
}

const Calllog = (props) => {
  const {
    reloadCallLogPage,
    data,
    setRefreshLoading,
    refreshBtn,
    setRefreshBtn,
    reload,
  } = props;

  const {
    fetchApi,
    showModalError,
    showModalErrorPage,
    openSnackbar,
    controllerRef,
  } = useContext(ContextState);

  const machineCodeList = useSelector(
    (state) => state.userFilters.data.data.machines_data
  );

  const datePickerRef = useRef();

  // Adding of new Uid state
  const [addUid, setaddUid] = useState({
    addUidStatus: false,
    fromDate: getFormattedDate(),
    toDate: getFormattedDate("tomorrow"),
    uid: "",
    machine_code: [],
    note: "",
  });

  // previousu call log state
  const [callLogData, setCallLogData] = useState(null);
  const [uidData, setUidData] = useState(null); //uid list state
  const [loadingModalVisiblity, setLoadingModalVisiblity] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [callLogModal, setCallLogModal] = useState(false); //detailed call log modal state(isOpen)
  const [selectedRow, setSelectedRow] = useState(null);
  // when add of new uid relaod call log Data
  const [relaodData, setReloadData] = useState(false);

  // media querries
  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const [fieldError, setFieldError] = useState({
    machineError: false,
  });

  // useEffect
  let isMounted;
  useEffect(() => {
    isMounted = true;
    if (isMounted) {
      getCallLogData();
    }

    return () => {};
  }, [relaodData, reload, reloadCallLogPage]);

  // closing of date range calendar
  const handleCancel = () => {
    // console.log("close Date Picker :", datePickerRef.current);
    datePickerRef.current.closeCalendar();
    // if (state?.dates?.length === 0 || state?.dates?.length > 1) {
    //   datePickerRef.current.closeCalendar();
    // }
  };

  // converting the date into dd-mm-yyyy format
  function convertDateFormat(dateStr) {
    const [day, month, year] = dateStr.split("/");
    return `${year}-${month}-${day}`;
  }

  // getting todays date and tomorrow date (default date)
  function getFormattedDate(option) {
    let date;

    if (option === "tomorrow") {
      date = new Date(Date.now() + 86400000);
    } else {
      date = new Date();
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  // getting uid list by choosing the date range
  const fetch_uid_list = async () => {
    try {
      setFieldError((prev) => ({ ...prev, machineError: false }));
      setBtnLoading(true);
      const payload = {
        from_date: convertDateFormat(addUid.fromDate),
        to_date: convertDateFormat(addUid.toDate),
      };
      const response = await fetchApi(
        "get_uuid_list",
        "POST",
        payload,
        isMounted
      );

      if (response.statusCode === 200) {
        setUidData(response.data);
        openSnackbar(response.data.message);
      } else {
        setUidData(null);
        openSnackbar(response.msg, "warning");
      }
    } catch (error) {
      if (controllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setBtnLoading(false);
    }
  };

  // link uid to database
  const add_uid = async () => {
    if (addUid.machine_code.length === 0) {
      setFieldError((prev) => ({
        ...prev,
        machineError: true,
      }));
      return;
    }

    try {
      setBtnLoading(true);
      const payload = {
        ticket_id: data.ticketId,
        uuid: addUid.uid,
        machines: addUid.machine_code,
        notes: addUid.note,
      };
      const response = await fetchApi(
        "link_uuid_ticket",
        "POST",
        payload,
        isMounted
      );
      if (response.statusCode === 200) {
        setaddUid((prev) => ({
          ...prev,
          addUidStatus: false,
          fromDate: getFormattedDate(),
          toDate: getFormattedDate("tomorrow"),
          uid: "",
          machine_code: [],
          note: "",
        }));
        openSnackbar(response.data.message);
        setReloadData((prev) => !prev);
      } else if (response.statusCode === 500) {
        openSnackbar(response.msg, "warning");
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  };

  // getting previous call log Data
  const getCallLogData = async () => {
    try {
      const payload = {
        ticket_id: data.ticketId,
      };

      if (refreshBtn) {
        setRefreshLoading(true);
      }
      const response = await fetchApi(
        "get_ivr_call_logs",
        "POST",
        payload,
        isMounted
      );

      if (response.statusCode === 200) {
        setCallLogData(response.data);
      } else if (response.statusCode !== 200) {
        setCallLogData(null);
        showModalErrorPage(response.statusCode, response.err);
      }
    } catch (error) {
    } finally {
      setLoadingModalVisiblity(false);
      setRefreshLoading(false);
      setRefreshBtn(false);
    }
  };

  // clicking on row plus button opening new sub detailed modal
  const handleTableRowClick = (selectedRowData) => {
    setCallLogModal(true);
    setSelectedRow({ ...selectedRowData });
  };

  // customizing the cells
  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      headers.push({
        field: "expand",
        headerName: "",
        width: 65,
        editable: false,
        sortable: false, // Disable sorting for this column
        filterable: false, // Disable filtering for this column
        disableColumnMenu: true,
        renderCell: (params) => {
          const eventType = params.row.event_type;

          let callBackgroundColor;
          let callMessage;
          switch (eventType) {
            case "Call Received":
              callBackgroundColor = "#acd842";
              callMessage = "Call Received";
              break;
            case "Dialed Call":
              callBackgroundColor = "#389bee";
              callMessage = "Dialed Call";

              break;
            case "Missed from support":
              callBackgroundColor = "#fb6f61";
              callMessage = "Missed from support";

              break;
            default:
              // Missed from matt operator
              callMessage = "Missed from matt operator";
              callBackgroundColor = "#f8981d";

              break;
          }
          return (
            <Box
              sx={{
                textAlign: "center",
                fontFamily: "Open-Sans-Medium",
                fontWeight: "700",
                fontSize: "1.05rem",
                textWrap: "nowrap",
                padding: "10px",
              }}
            >
              {
                <Tooltip
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          fontFamily: "Open-Sans-Medium",
                        }}
                      >
                        {callMessage}
                      </div>
                    </>
                  }
                  arrow
                >
                  <div
                    style={{
                      cursor: "pointer",
                      cursor: "pointer",
                      backgroundColor: callBackgroundColor,
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      if (params.row) {
                        handleTableRowClick(params.row);
                      }
                    }}
                  >
                    <FaPhoneAlt style={{ color: "white" }} />
                  </div>
                </Tooltip>
              }
            </Box>
          );
        },
      });
      data.column.forEach((item, index) => {
        if (!["uuid", "notes"].includes(item.field)) {
          headers.push({
            field: item.field,
            headerName: item.headerName,
            width: item.width,
            editable: item.editable,
            flex: [
              "Event Message",
              "UID",
              "Caller Number",
              "Event Type",
              "Date",
            ].includes(item.headerName)
              ? ""
              : !islageScreen,
            headerAlign: index !== 1 ? "center" : "left",

            renderHeader: (params) => (
              <Tooltip
                title={`${item.field
                  .replace(/_/g, " ")
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}`}
                arrow
              >
                <span
                  style={{
                    paddingLeft: ([1].includes(index) && "10px") || "0px",
                  }}
                >
                  {params.colDef.headerName}
                </span>
              </Tooltip>
            ),
            renderCell: (params) => {
              // console.log("params-->: ", params);
              const finalParamValue =
                item.field === "start_time"
                  ? addTimeAndFormat(params.value)
                  : Array.isArray(params?.value)
                  ? params.value.length > 0
                    ? params.value.join(",")
                    : ""
                  : params.value;
              return (
                <Box
                  sx={{
                    textAlign: index !== 1 ? "center" : "left",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight: (index !== 1 && "500") || "700",
                    fontSize: item.field === "status" ? "0.85rem" : "1.05rem",
                    color: color.tableRowHeading,
                    textWrap: "nowrap",
                  }}
                >
                  {
                    <Tooltip
                      title={
                        <>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              fontFamily: "Open-Sans-Medium",
                            }}
                          >
                            <div>
                              <strong style={{ textTransform: "capitalize" }}>
                                {params.field.split("_").join(" ")}
                              </strong>
                              :{" "}
                              <strong
                                style={{
                                  textTransform: "none",
                                }}
                              >
                                {finalParamValue}
                              </strong>
                            </div>
                          </div>
                        </>
                      }
                      arrow
                    >
                      <span
                        style={{
                          // border: "2px solid blue",
                          padding: "4px 9px",
                          borderRadius: "4px",
                          textTransform: "none",
                        }}
                      >
                        {finalParamValue}
                      </span>
                    </Tooltip>
                  }
                </Box>
              );
            },
          });
        }
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
      //   rows.push({ ...item, scan_id: trimAfterLastDot(item["scan_id"]) });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  // data for headers and row for data grid table
  const { TableDataHeaders, TabelDataRows } = (callLogData &&
    callLogData.rows?.length !== 0 &&
    getFirstPageDataTableDataHeader({
      rows: callLogData.rows || [],
      column: callLogData.columns || [],
    })) || { TableDataHeaders: [], TabelDataRows: [] };

  // closing of sub modal
  const onCloseCallLogModal = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    } else {
      setCallLogModal(false);
      setSelectedRow(null);
    }
  };

  return (
    <>
      {(loadingModalVisiblity && (
        <div
          className="showSpinner "
          style={{ position: "relative", height: "88vh", width: "100%" }}
        >
          <BeatLoader color="#2D97D4" />
        </div>
      )) ||
        (!showModalError?.status && (
          <div className="main-attachment-container p-2">
            <div className="p-3">
              <Tooltip
                data-html="true"
                arrow={true}
                title="Click here to add unique call id."
              >
                <button
                  type="button"
                  className="btn btn-outline-primary markResolved_button"
                  //   disabled={ticketStatus === "resolved"}
                  onClick={() =>
                    setaddUid((prev) => ({
                      ...prev,
                      addUidStatus: !prev.addUidStatus,
                    }))
                  }
                >
                  Add Call Log
                </button>
              </Tooltip>

              {/* add Uid form starts */}
              {addUid.addUidStatus && (
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 12px 0px",
                    margin: "24px 0px 0px 0px",
                    borderRadius: "10px",
                    padding: "20px 24px",
                  }}
                >
                  <p className="mb-3 pb-1">Add Unique Call Id</p>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: isSmallScreen
                        ? "1fr"
                        : islageScreen
                        ? "1fr 1fr"
                        : "1fr 1fr 2fr",
                      gap: "15px",
                    }}
                  >
                    <div
                      className="w-100 date-picker-container"
                      // style={{ maxWidth: isSmallSmallScreen ? "" : "400px" }}
                    >
                      <label className="floating-label">
                        Select Date Range
                      </label>
                      <DatePicker
                        ref={datePickerRef}
                        onChange={(e, dates) => {
                          setaddUid((prev) => ({
                            ...prev,
                            fromDate: dates.validatedValue[0],
                            toDate: dates.validatedValue[1],
                            uid: "",
                            machine_code: [],
                            notes: "",
                          }));
                          setUidData(null);
                        }}
                        value={[addUid.fromDate, addUid.toDate]}
                        range
                        rangeHover
                        format={"DD/MM/YYYY"}
                        dateSeparator=" to "
                        render={<InputIcon />}
                      >
                        <button
                          className="cancel_button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </DatePicker>
                    </div>

                    {uidData?.length > 0 && (
                      <div
                        className="w-100"
                        // style={{ maxWidth: isSmallSmallScreen ? "" : "400px" }}
                      >
                        <Autocomplete
                          disablePortal
                          size="medium"
                          id="Unique Id"
                          options={uidData?.uuid_list || []}
                          value={addUid.uid}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Uid" />
                          )}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setaddUid((prev) => ({
                                ...prev,
                                uid: newValue,
                              }));
                            } else {
                              setaddUid((prev) => ({
                                ...prev,
                                uid: "",
                              }));
                            }
                          }}
                        />
                      </div>
                    )}

                    {uidData?.length > 0 && (
                      <FormControl
                        style={{
                          display: "inline-block",
                          gridColumnStart: 1 * islageScreen || "",
                          gridColumnEnd: -1 * islageScreen || "",
                        }}
                        error={fieldError.machineError}
                      >
                        <Autocomplete
                          multiple
                          id="multi-select-label"
                          options={Object.entries(machineCodeList).filter(
                            ([key]) => !addUid.machine_code.includes(key)
                          )}
                          getOptionLabel={(option) =>
                            `${option[0]} (${option[1]})`
                          }
                          filterSelectedOptions
                          onChange={(event, values) => {
                            const finalValues = values.map((item) => item[0]);
                            setaddUid((prev) => ({
                              ...prev,
                              machine_code: finalValues,
                            }));
                            if (finalValues.length > 0) {
                              setFieldError((prev) => ({
                                ...prev,
                                machineError: false,
                              }));
                            }
                          }}
                          value={addUid.machine_code.map((code) => [
                            code,
                            machineCodeList[code],
                          ])}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Machines"
                              placeholder="Select Machine"
                              variant="outlined"
                              error={fieldError.machineError} // Add error prop here
                            />
                          )}
                        />

                        {fieldError.machineError ? (
                          <div
                            style={{
                              marginTop: "5px",
                              color: "red",
                              fontWeight: 300,
                              fontSize: "14px",
                              marginLeft: "10px",
                            }}
                          >
                            Please select machine
                          </div>
                        ) : (
                          <></>
                        )}
                      </FormControl>
                    )}

                    {/*Machine error helper text */}

                    {uidData?.length > 0 && (
                      <TextField
                        style={{ gridColumnStart: "1", gridColumnEnd: "-1" }}
                        fullWidth
                        id="outlined-multiline-static"
                        label="Note"
                        multiline
                        rows={4}
                        labelId="demo-simple-select-label"
                        value={addUid.note}
                        onChange={(event) => {
                          setaddUid((prev) => ({
                            ...prev,
                            note: event.target.value,
                          }));
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-end pt-3">
                    <LoadingButton
                      size="medium"
                      onClick={addUid.uid.length > 1 ? add_uid : fetch_uid_list}
                      endIcon={<SendIcon />}
                      loading={btnLoading}
                      disabled={
                        addUid.toDate && addUid.fromDate
                          ? // &&
                            // addUid.machine_code.length > 0
                            false
                          : true
                      }
                      loadingPosition="end"
                      variant="contained"
                    >
                      <span>
                        {addUid.uid.length > 1 ? "Add UID" : "Get Uid List"}
                      </span>
                    </LoadingButton>
                  </div>
                </div>
              )}
              {/* add Uid form ends */}

              {/* call log list starts  Data grid*/}
              {callLogData?.rows?.length > 0 ? (
                <div className="pt-4">
                  <Box
                    className="card-Div p-0"
                    sx={{
                      width: "100%",
                      "& .MuiDataGrid-columnHeader": {
                        // backgroundColor: "rgba(234, 235, 237, 0.75)",
                        color: "black",
                      },
                      "& .MuiDataGrid-row": {
                        backgroundColor: "white",
                        color: "black",
                      },
                      "& .MuiDataGrid-selectedRowCount": {
                        display: "none",
                      },
                    }}
                  >
                    <DataGrid
                      rows={TabelDataRows}
                      columns={TableDataHeaders}
                      sx={{
                        "&.MuiDataGrid-root": {
                          border: "none",
                          borderRadius: "5px", // Adjust border radius as needed
                          overflow: "hidden", // Ensure content respects the border radius,
                          padding: "0px",
                          margin: "0px",
                          marginTop: "-10px",
                        },
                        ".MuiDataGrid-columnHeader": {
                          backgroundColor: color.textWhiteColor,
                          // justifyContent: "center",
                        },

                        ".MuiDataGrid-columnHeaderTitleContainer": {
                          // justifyContent: "center",
                        },
                        ".MuiDataGrid-filler": {
                          backgroundColor: color.textWhiteColor,
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                          fontFamily: "Open-Sans-Light",
                          fontWeight: "bold",
                          padding: "5px 0px",
                          fontSize: "0.85rem",
                          textTransform: "capitalize",
                          color: color.taxtHeading,
                        },
                        ".MuiDataGrid-columnHeader--alignCenter": {
                          headerAlign: "left",
                        },
                        ".MuiDataGrid-row": {
                          border: "none", // Remove border from rows
                          backgroundColor: color.textWhiteColor, // Set rows background color to white
                          "&:hover": {
                            backgroundColor: color.primary, // Ensure background color remains white on hover
                            // cursor: "pointer",
                          },
                        },

                        ".MuiDataGrid-cell": {
                          outline: "none",
                          "&:focus": {
                            outline: "none", // Remove the blue outline when a cell is focused
                          },
                        },
                        // Custom CSS to hide the "Rows per page" component

                        ".MuiDataGrid-footerContainer": {
                          display: "none", // Hide the pagination footer
                        },
                        ".MuiTablePagination-root": {
                          ".MuiInputBase-root": {
                            display: "none",
                          },

                          ".MuiTablePagination-selectLabel": {
                            display: "none",
                          },
                          ".MuiToolbar-root": {
                            marginTop: "12px",
                          },
                        },
                        "&.selected-row": {
                          backgroundColor: "red", // Highlight color for the selected row
                          "&:hover": {
                            backgroundColor: "red ", // Ensure highlight color remains on hover
                          },
                        },
                        ".MuiDataGrid-selectedRowCount": {
                          display: "none",
                        },
                      }}
                    />

                    {callLogModal && (
                      <CallLogModal
                        isOpen={callLogModal}
                        onClose={onCloseCallLogModal}
                        selectedRowData={selectedRow}
                        isSmallScreen={isSmallScreen}
                        isSmallSmallScreen={isSmallSmallScreen}
                        isMediumScreen={isMediumScreen}
                      />
                    )}
                  </Box>
                </div>
              ) : (
                <div className="show-comments m-0 mt-3">
                  <h5
                    className="previous-comments-header-text pb-1"
                    style={{ color: "rgba(76, 78, 100, 0.7)" }}
                  >
                    Previous Call Logs
                  </h5>
                  No call logs added yet
                </div>
              )}
              {/* call log list ends*/}
            </div>
          </div>
        )) ||
        (showModalError?.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
};

export default Calllog;
